@import "../../../assets/fonts/Iskoola Pota.ttf";


.contactUs {
  font-family: "Iskoola Pota" !important;
  padding-top: 50px;
  padding-bottom: 100px;

  .questionForm {
    font-family: "Iskoola Pota" !important;
    padding: 30px;
    border-radius: 20px;
    background-color: transparent !important;
    border-width: 4px;
  }
}

.contact {
  .video {
    width: 100%;
  }

  .WaitingIMG {
    width: 60%;
    position: absolute;
    top: 83px;
    left: 70px;
  }
}


@media (max-width:1025px) {
  .contact {
    .video {
      width: 100%;
    }
    .WaitingIMG {
      width: 70%;
      position: absolute;
      top: 92px;
      left: 18px;
    }
  }
}

@media (max-width:769px) {
  .contact {
    .video {
      width: 100%;
    }
    .WaitingIMG {
      width: 100%;
      position: absolute;
      top: 118px;
      left: 18px;
    }
  }
}

@media (max-width: 426px) {
  .contact {
    img {
      display: none !important;
    }
  }
}

@media (max-width: 376px) {
  .contact {
    img {
      display: none !important;
    }
  }
}
@media (max-width: 320px) {
  .contact {
    img {
      display: none !important;
    }
  }
}