@font-face {
    font-family: "Iskoola Pota";
    src: url("assets/fonts/Iskoola Pota.ttf") ;
}


body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue","Iskoola Pota",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New","Iskoola Pota",
    monospace;
}


.at-containe {
    position: absolute;
    top: 40%;
    left: 48%;
}

/*div.at-container {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  height: 100%;*/
/*}*/
.at-item {
    background-color: #fff;
    border: 8px #4457c3 dotted;
    height: 80px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    width: 80px;
    animation-name: rotate-center;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(360deg);
    }
}


@media (max-width: 769px) {
  .at-containe {
    position: absolute;
    justify-content: center;
    top: 40%;
    right: 0;
    bottom: 0;
    left: 45%;
  }
}


@media (max-width: 426px) {
  .at-containe {
    position: absolute;
    justify-content: center;
    top: 40%;
    right: 0;
    bottom: 0;
    left: 38%;
  }
}
@media (max-width: 426px) {
  .at-containe {
    position: absolute;
    justify-content: center;
    top: 40%;
    right: 0;
    bottom: 0;
    left: 38%;
  }
}
@media (max-width: 376px) {
  .at-containe {
    position: absolute;
   justify-content: center;
    top: 40%;
    right: 0;
    bottom: 0;
    left: 37%;
  }
}
@media (max-width: 320px) {
  .at-containe {
    position: absolute;
   justify-content: center;
    top: 40%;
    right: 0;
    bottom: 0;
    left: 36%;
  }
}