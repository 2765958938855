
@import "../../../assets/fonts/Iskoola Pota.ttf";


.aboutCardGrid{
  .imgCard{}
  .contentCard{
    font-family: "Iskoola Pota" !important;

    background-color: #f6f6f6 !important;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(199, 199, 199, 0.1), 0 6px 20px 0 rgba(199, 199, 199, 0.1);
    justify-content: center;
    padding: 10px;


    h1{
      font-family: "Iskoola Pota" !important;

      position: absolute;
      right: 30px;
      top: -10px;
      font-size: 10em;
      color: rgba(0,0,0,0.08);
    }
    h3{
      font-family: "Iskoola Pota" !important;

      text-align: left;
      padding-top: 20px;
    }

    .resourceLinks{
      font-family: "Iskoola Pota" !important;

      margin: 5px;
    }
    .btnRecourse{
      font-family: "Iskoola Pota" !important;

      padding:5px;
      width:100%;
      text-align:center;
      border-radius:10px;
    }
  }
}


@media (max-width: 769px) {

  .contentCard{
    margin: 0px;
  }
  .btnRecourse{
    margin-bottom: 10px;
  }

}
@media (max-width: 426px) {
  .imgCard{
    display: none;
  }
  .contentCard{
    margin: 10px;
  }
  .btnRecourse{
   margin-bottom: 10px;
  }

}
@media (max-width: 376px) {
  .imgCard{
    display: none;
  }
  .contentCard{
    margin: 10px;
  }
  .btnRecourse{
   margin-bottom: 10px;
  }

}
@media (max-width: 320px) {
  .imgCard{
    display: none;
  }
  .contentCard{
    margin: 10px;
  }
  .btnRecourse{
   margin-bottom: 10px;
  }

}