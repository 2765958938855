@import "../../../assets/fonts/Iskoola Pota.ttf";


.versionView{
  margin-top: 4em;
  margin-bottom: 4em;
  .UIView{
    margin-top: 50px;
    .UITopic{
      margin-bottom: 25px;
    }
  }
}

@media (max-width:769px) {
  .versionView{
  margin-top: 2em;
  margin-bottom: 2em;
  .UIView{
    margin-top: 40px;
    .UITopic{
      margin-bottom: 15px;
    }
  }
  }
}
@media (max-width:426px) {
  .versionView{
  margin-top: 2em;
  margin-bottom: 2em;
  .UIView{
    margin-top: 40px;
    .UITopic{
      margin-bottom: 15px;
    }
  }
  }
}
@media (max-width:376px) {
  .versionView{
  margin-top: 2em;
  margin-bottom: 2em;
  .UIView{
    margin-top: 40px;
    .UITopic{
      margin-bottom: 15px;
    }
  }
  }
}
@media (max-width: 320px) {
  .versionView{
  margin-top: 2em;
  margin-bottom: 2em;
  .UIView{
    margin-top: 40px;
    .UITopic{
      margin-bottom: 15px;
    }
  }
  }
}