
@import "../../../assets/fonts/Iskoola Pota.ttf";

.workFlow{
  font-family: "Iskoola Pota" !important;
  margin-top:5em;
  margin-bottom: 8em;

  .services li{
    font-family: "Iskoola Pota" !important;
   margin: 8px;
  }
}



@media (max-width: 769px) {
  .workFlow {
    margin-top: -2em;
    margin-bottom: 2em;
  }
  .topicWorkFlow{
    font-size: 30px!important;
  }
}

@media (max-width: 426px) {
  .workFlow {
    margin-top: -2em;
    margin-bottom: 2em;
  }
  .topicWorkFlow{
    font-size: 30px!important;
  }
}

@media (max-width: 376px ) {
  .workFlow {
    margin-top: -2em;
    margin-bottom: 2em;
    .topicWorkFlow{
      font-size: 26px!important;
    }
  }
}

@media (max-width: 320px ) {
  .workFlow {
    margin-top: -2em;
    margin-bottom: 2em;
    .topicWorkFlow{
      font-size: 22px!important;
    }
  }
}