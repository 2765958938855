@import "../../../assets/fonts/Iskoola Pota.ttf";

.landing {
  height: 55vh;
  background-attachment: fixed;

  .firstView {
    top: 17em;
    left: 8.7em;
    position: absolute;
    z-index: 3;

    .welcomePara {
      font-size: 20px;
      white-space: pre-line;
    }

    .btnLanding {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 0;
      margin-right: 10px;
    }
  }
}


.isSafariLoad {
  background-image: url("../../../assets/img/New/learning.webp");
  object-fit: cover;
  height: 100vh;

  .firstView {
    top: 23.0em;
    left: 8.7em;
    position: absolute;
    z-index: 3;

    .btnLanding {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 0;
      margin-right: 10px;
    }
  }
}

.testBackgroundVideo {
  //position: absolute;
  width: 100% !important;
  height: 80vh;
  padding: 0;
  margin: 0;
  object-fit: cover;
}

@media (max-width: 1025px) {

  .landing {
    height: 50vh;
    background-attachment: fixed;
  }

  .testBackgroundVideo {
    //position: absolute;
    height: 50vh;

  }

  .firstView {
    top: 10em !important;
    left: 2.7em !important;

    .welcomePara {
      font-size: 18px !important;
      font-family:"Iskoola Pota";
    }
    h1 {
      color: whitesmoke !important;
    }

    h2, p {
      color: whitesmoke !important;
    }

    .btnSection {
      margin-top: 20px;
    }

    .btnLanding {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-left: 0 !important;
      margin-right: 10px !important;
    }
  }

  //.testBackgroundVideo {
  //  display: none !important;
  //}
}

@media (max-width: 769px) {

  .landing {
    background-attachment: fixed;
    height: 58vh;
  }

  .testBackgroundVideo {
    //position: absolute;
    height: 60vh;

  }

  .firstView {
    top: 8em !important;
    left: 0.7em !important;

    .welcomePara {
      font-size: 16px !important;
      font-family:"Iskoola Pota";

    }

    h1 {
      color: whitesmoke !important;
    }

    h2, p {
      color: whitesmoke !important;
    }

    .btnSection {
      margin-top: 20px;
    }

    .btnLanding {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-left: 0 !important;
      margin-right: 10px !important;
    }
  }

  //.testBackgroundVideo {
  //  display: none !important;
  //}
}

@media (max-width: 426px) {

  .landing {
    background-image: url("../../../assets/img/New/learning.webp");
    object-fit: cover;
    height: 72vh;
  }

  .firstView {
    top: 12em !important;
    left: 0.7em !important;

    .welcomePara {
      font-size: 15px !important;
      font-family:"Iskoola Pota";

    }
    h1 {
      color: whitesmoke !important;
    }

    h2, p {
      color: whitesmoke !important;
    }

    .btnSection {
      margin-top: 20px;
    }

    .btnLanding {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-left: 0 !important;
      margin-right: 10px !important;
    }
  }

  .testBackgroundVideo {
    display: none !important;
  }
}

@media (max-width: 376px) {

  .landing {
    background-image: url("../../../assets/img/New/learning.webp");
    object-fit: cover;
    height: 65vh;
  }

  .firstView {
    top: 7em !important;
    left: 0.7em !important;

    .welcomePara {
      font-size: 14px !important;
      font-family:"Iskoola Pota";

    }
    h1 {
      color: whitesmoke !important;
    }

    h2, p {
      color: whitesmoke !important;
    }

    .btnSection {
      margin-top: 30px;
    }


    .btnLanding {
      margin-top: 0px !important;
      margin-bottom: 0 !important;
      margin-left: 4px !important;
      margin-right: 10px !important
    }
  }

  .testBackgroundVideo {
    display: none !important;
  }
}

@media (max-width: 320px) {

  .landing {
    width: 100%;
    background-image: url("../../../assets/img/New/learning.webp");
    object-fit: cover;
    height: 75vh !important;
  }

  .firstView {
    top: 8em !important;
    left: 0.7em !important;

    .welcomePara {
      font-size: 13px !important;
      font-family:"Iskoola Pota";

    }
    h1 {
      color: whitesmoke !important;
    }

    h2, p {
      color: whitesmoke !important;
    }

    .btnSection {
      margin-top: 20px;
      position: inherit;
    }

    .btnLanding {
      margin-top: 10px !important;
      margin-bottom: 0 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .testBackgroundVideo {
    display: none !important;
  }
}