@import "../../../assets/fonts/Iskoola Pota.ttf";


.latestProject {
  margin-top: 15em;
  margin-bottom: 3em;

  .projectCard {
    width: 100%;
    padding: 5px;
    border-width: 4px;
    border-radius: 10px;

    .projectDetails {
      padding: 10px;
      border-color: black;
      border-width: 5px;

    }
  }
}
@media (max-width: 1025px) {
  .latestProject {
    margin-top: 3em;
    margin-bottom: 3em;

    .projectCard{
      margin-bottom: 18px;
    }
  }
}

@media (max-width: 769px) {
  .latestProject {
    margin-top: 3em;
    margin-bottom: 3em;

    .projectCard{
      margin-bottom: 18px;
    }
  }
}

@media (max-width: 426px) {
  .latestProject {
    margin-top: 3em;
    margin-bottom: 3em;

    .projectCard{
      margin-bottom: 18px;
    }
  }
}

@media (max-width: 320px) {
  .latestProject {
    margin-top: 3em;
    margin-bottom: 3em;

    .projectCard{
      margin-bottom: 18px;
    }
  }
}