$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 767px;
$value_two: 768px;
$value_three: 991px;
$value_four: 992px;
$value_five: 1199px;
$value_six: 1550px;
$value_seven: 505px;

@font-face {
	font-family: "Iskoola Pota";
	src: url("../fonts/Iskoola Pota.ttf") ;
}


@media only #{$media} and ($feature_max : $value_one) {
    .mt-100 {
		margin-top: 40px;
	}
	.ptb-100 {
		padding: {
			top: 60px;
			bottom: 60px;
		}
	}
	.btn {
		font-size: 15px;
		padding: 9px 17px;
	}
	.owl-theme {
		&:hover {
			.owl-nav {
				[class*="owl-"] {
					left: 2px;
				}
				.owl-next {
					left: auto;
					right: 2px;
				}
			}
		}
	}
	.section-title {
		margin-bottom: 40px;

		h3 {
			font-size: 22px;
		}
	}
    .navbar-expand-lg {
		.container, .container-fluid {
			padding: {
				right: 15px;
				left: 15px;
			}
		}
	}
    .navbar-light {
		.navbar-toggler {
			outline: 0;
			padding: 4px 10px;

			.navbar-toggler-icon {
				width: 1.3em;
				height: 1.3em;
			}
		}
		.navbar-nav {
			margin-top: 10px;

			.nav-link {
				padding: {
					left: 0;
					right: 0;
				}
			}
			.dropdown {
				.dropdown-menu {
					position: absolute;
				}
			}
		}
	}
    .main-banner {
        height: 100%;
		padding: {
			top: 20px;
			bottom: 80px;
		}
    }
    .main-banner-content {
        text-align: left;
		margin-top: 0;

		h1 {
			font-size: 30px;
			margin-bottom: 20px;
			line-height: 40px;
		}
		.btn-primary {
			margin-right: 3px;
		}
    }
    .main-banner-form {
		margin-top: 30px;
		padding-bottom: 25px;

		h3 {
			font-size: 20px;
			margin-bottom: 25px;
			padding: 18px 0;
		}
		form {
			padding: {
				left: 18px;
				right: 18px;
			}
		}
	}
	.banner-form {
		padding: 25px 18px;
		margin-top: 30px;
	}
	.hero-form {
		padding: 25px 18px;
		margin-top: 30px;
		border-width: 3px;
	}
	.banner-img {
		margin-top: 40px;
	}
	.creative-bg {
		height: 50%;
	}
	.creative-bg-two {
		display: none;
	}
	.main-banner {
		.bg-bottom {
			bottom: auto;
			top: 0;
		}
	}
	.features-area {
		padding-bottom: 20px;
	}
	.single-features {
		padding: 20px;
		margin-bottom: 30px;
	}
	.about-content {
		h3 {
			font-size: 20px;
			line-height: 32px;
		}
		.pull-left {
			float: none;
			margin-right: 0 !important;
			margin-bottom: 10px;
		}
	}
	.about-img {
		margin-top: 40px;
		border-bottom: 1px solid #eeeeee;
		padding-bottom: 35px;
	}
	.benifits-text {
		margin: {
			top: 40px;
			bottom: 40px;
		}

		span {
			font-size: 17px;
			width: 40px;
			height: 40px;
			line-height: 43px;
		}
		h3 {
			font-size: 20px;
			margin-top: 20px;
		}
	}
	.benifits-content {
		h3 {
			font-size: 20px;
			line-height: 32px;
		}
		.pull-left {
			float: none;
			margin-right: 0 !important;
			margin-bottom: 10px;
		}
	}
	.benifits-img {
		margin-top: 40px;
		border-bottom: 1px solid #eeeeee;
		padding-bottom: 35px;
	}
	.benifits-text {
		margin: {
			top: 40px;
			bottom: 40px;
		}

		span {
			font-size: 17px;
			width: 40px;
			height: 40px;
			line-height: 43px;
		}
		h3 {
			font-size: 20px;
			margin-top: 20px;
		}
	}
	.services-area {
		padding-bottom: 30px;
	}
	.single-services {
		padding: 20px;
		text-align: center;
	}
	.tab {
		.tab_content {
			.react-tabs {
				.react-tabs__tab-list {
					margin-bottom: 25px !important;
					padding-bottom: 5px !important;

					li {
						-ms-flex: 0 0 50%;
						flex: 0 0 50%;
						max-width: 50%;
						padding: {
							right: 0;
							left: 0;
						}
						margin-bottom: 25px;
						text-align: center;
						font-size: 16px;

						i {
							width: 50px;
							height: 50px;
							line-height: 50px;
							font-size: 26px;
							margin-bottom: 15px;
						}
					}
				}
				.tabs_item {
					.tabs_item_content {
						margin: {
							bottom: 30px;
							top: 30px;
						}
						h3 {
							font-size: 20px;
						}
					}
				}
			}
		}
	}
	.funfacts-area {
		padding: {
			top: 60px;
			bottom: 20px;
		}
	}
	.funFact {
		padding: {
			top: 0;
			bottom: 0;
		}
		margin-bottom: 40px;

		&::before, &::after {
			display: none;
		}
		i {
			font-size: 25px;
			line-height: 54px;
			width: 55px;
			height: 55px;
		}
		h3 {
			font-size: 30px;
		}
		p {
			font-size: 18px;
			line-height: 20px;
		}
	}
	.fun-fact {
        padding-left: 0;
        text-align: center;
		margin-bottom: 40px;

		i {
			font-size: 20px;
            width: 50px;
            height: 50px;
            line-height: 50px;
            position: relative;
            margin-bottom: 15px;
		}
		h3 {
			font-size: 30px;
		}
		p {
			font-size: 17px;
		}
    }
    .newsletter {
        .subscribe-form {
            .form-control {
                font-size: 14px;
                height: 60px;
            }
            .btn {
                position: relative;
                display: block;
                width: 100%;
                height: 50px;
            }
        }
    }
	.join-content {
		margin-top: 40px;

		h3 {
			font-size: 20px;
			line-height: 32px;
		}
	}
	.how-works-area {
		padding-bottom: 30px;
	}
	.single-box {
		text-align: center;
		padding: 20px;
		margin-bottom: 30px;

		&.with-line {
			&::before {
				display: none;
			}
		}
		span {
			width: 65px;
			height: 65px;
			line-height: 70px;
			font-size: 25px;
		}
		h3 {
			font-size: 20px;
		}
	}
	.our-team {
		.title {
			font-size: 20px;
		}
	}
	.testimonials-area {
		padding-bottom: 30px;

		.section-title {
			position: relative;
		}
	}
	.testimonials-item {
		padding: 20px;

		.client-info {
			.client-title {
				h4 {
					font-size: 18px;
				}
			}
		}
	}
	.faq-img {
		margin-top: 40px;
	}
	.single-team {
		.title {
			font-size: 20px;
		}
	}
	.pricing-area {
		padding-bottom: 30px;
	}
	.pricingTable {
		padding-bottom: 20px;
		margin-bottom: 30px;

		.title {
			padding: 20px 20px 153px;
			margin: 0 0 30px 0;
			font-size: 20px;
		}
		.price-value {
			width: 115px;
			height: 115px;
			line-height: 60px;
			-webkit-box-shadow: 0 0 0 5px rgba(0,0,0,0.3);
			box-shadow: 0 0 0 5px rgba(0,0,0,0.3);
			padding: 18px 0;
			font-size: 24px;
			top: 80px;
		}
	}
	.pricing-table {
		margin-bottom: 30px;

		.title {
			font-size: 20px;
		}
	}
	.react-sanfona-item-body-wrapper {
		p {
			margin-top: 15px;
		}
	}
	.newsletter {
		max-width: unset;
		padding: 20px;
	}
	.subscribe-area {
		.bg-bottom {
			display: none;
		}
	}
	.subscribe-area {
		.bg-top {
			height: 285px;
		}
	}
	.newsletter {
		h4 {
			font-size: 20px;
			line-height: 29px;
		}
	}
	.newsletter {
		form {
			.form-control {
				height: 60px;
				border-radius: 0;
				padding-left: 16px;
				font-size: 16px;
			}
			.btn {
				position: relative;
				right: 0;
				border-radius: 6px;
				top: 0;
				height: 50px;
				width: 100%;
				margin-top: 20px;
			}
		}
	}
	.subscribe-area {
		.pattern {
			height: 60px;
		}
		.pattern-2 {
			height: 60px;
		}
	}
	.page-title {
		height: 250px;

		.bg-top, .bg-bottom {
			display: none;
		}
		.pattern-2 {
			height: 120px;
		}
		.page-title-content {
			h3 {
				font-size: 22px;
			}
		}
	}
	.contact-box {
		.content {
			h4 {
				font-size: 18px;
			}
		}
	}
	.contact-text {
		h3 {
			font-size: 20px;
		}
	}
	#contactForm {
		margin-top: 30px;
	}
	.footer-area {
		padding-bottom: 0;
	}
	.single-footer {
		margin-bottom: 35px;

		h3 {
			font-size: 20px;
		}
		ul {
			li {
				a {
					font-size: 16px;
				}
			}
		}
	}
	.copyright-area {
		margin-top: 30px;
		padding: 30px 0;
		text-align: center;

		p {
			margin-top: 0;
		}
		ul {
			text-align: center;
			margin-top: 15px;

			li {
				a {
					width: 33px;
					height: 33px;
					line-height: 31px;
					font-size: 15px;
				}
			}
		}
	}

	.top-header {
		.header-social {
			text-align: center;
			margin-bottom: 10px;

			li {
				span {
					font-size: 13px;
				}
				a {
					font-size: 15px;
				}
			}
		}
		.location {
			text-align: center;

			span {
				font-size: 13px;
			}
		}
	}
	.repair-main-banner {
		padding: {
			top: 140px;
			bottom: 270px;
		}
	}
	.repair-banner-content {
		text-align: center;

		h2 {
			line-height: 1.4;
			font-size: 30px;
			margin-bottom: 16px;
		}
		p {
			margin-bottom: 20px;
			font-size: 13px;
		}
	}
	.banner-video {
		margin-top: 45px;

		a {
			width: 75px;
			height: 75px;
			line-height: 78px;
			font-size: 25px;
			margin-left: 0;

			&::after {
				width: 75px;
				height: 75px;
			}
		}
	}
	.repair-home-slides {
		&.owl-theme {
			.owl-dots {
				left: 0;
				bottom: 28%;
				right: 0;
			}
		}
	}
	.repair-boxes-area {
		padding-bottom: 0;
	}
	.repair-about-content {
		ul {
			li {
				width: 100%;
				font-size: 14px;
			}
		}
	}
	.repair-about-image {
		margin-top: 35px;
		text-align: center;
		padding: {
			bottom: 45px;
			top: 45px;
		}
		img {
			&:nth-child(2) {
				border: 3px solid #f8fbff;
				width: 255px;
				position: relative;
			}
			&:nth-child(4), &:nth-child(5) {
				display: none;
			}
		}
	}
	.single-repair-services {
		padding: 20px;

		h3 {
			margin: {
				bottom: 18px;
				top: 20px;
			}
		}
	}
	.repair-services-inner {
		.col-lg-4 {
			&:nth-child(2) {
				border: none;
				margin: {
					top: 30px;
					bottom: 30px;
				}
			}
		}
	}
	.single-repair-feedback {
		padding: 20px;
	}
	.gallery-area {
		padding-bottom: 30px;
	}
	.gallery-shorting-menu {
		margin-bottom: 30px;

		.filter {
			font-size: 14px;
			margin-bottom: 8px;
		}
	}
	.single-gallery-item {
		a {
			img {
				width: 100%;
			}
		}
	}
	.repair-blog-area {
		padding-bottom: 30px;
	}
	.single-repair-blog-post .blog-image {
		a {
			img {
				width: 100%;
			}
		}
	}
	.repair-subscribe-area {
		margin: {
			top: -60px;
			bottom: 60px;
		}
	}
	.repair-subscribe-content {
		padding: {
			top: 25px;
			bottom: 25px;
			left: 15px;
			right: 15px;
		}
		h2 {
			margin-bottom: 15px;
			font-size: 24px;
		}
		form {
			.form-group {
				width: 100%;
				padding-right: 0;
				margin-bottom: 15px;
			}
			.btn {
				margin-top: 5px;
				width: 100%;
				padding: 12px 17px;
			}
		}
	}
	.repair-footer-area {
		padding-top: 60px;
	}
	.single-footer-widget {
		margin-bottom: 30px;

		h3 {
			margin-bottom: 20px;
		}
	}
	.repair-copyright-area {
		margin-top: 30px;
		text-align: center;

		.text-right {
			text-align: center !important;
		}
		ul {
			margin-top: 15px;
		}
	}
}

@media only #{$media} and ($feature_max : $value_seven) {
	.main-banner {
		height: 100%;
		padding: {
			top: 20px;
			bottom: 80px;
		}
	}
	.navbar-brand {
		//width: 385px;
		//width: 135px;
		img {
			max-width: 115px!important;
		}

	}
}

@media only #{$media} and ($feature_min : $value_two) and ($feature_max : $value_three) {
	.ptb-100 {
		padding: {
			bottom: 80px;
			top: 80px;
		}
	}
	.mt-100 {
		margin-top: 50px;
	}
	.btn {
		padding: 10px 24px;
	}
    .navbar-light {
		.navbar-toggler {
			outline: 0;
			padding: 4px 10px;

			.navbar-toggler-icon {
				width: 1.3em;
				height: 1.3em;
			}
		}
	}
    .navbar-light {
		.navbar-nav {
			margin-top: 10px;

			.nav-link {
				padding: {
					left: 0;
					right: 0;
				}
			}
			.dropdown {
				.dropdown-menu {
					position: absolute;
				}
			}
		}
	}
	.main-banner {
        height: 100%;
		padding: {
			top: 80px;
			bottom: 80px;
		}
    }
	.main-banner-content {
		margin-top: 0;

		h1 {
			font-size: 36px;
		}
		.btn-primary {
			margin-right: 3px;
		}
    }
    .main-banner-form {
		h3 {
			font-size: 20px;
			padding: 20px 0;
		}
	}
	.banner-form {
		padding: 20px 15px;
	}
	.hero-form {
		padding: 20px 15px;
		border-width: 3px;
	}
	.features-area {
		padding-bottom: 20px;
	}
	.single-features {
		margin-bottom: 30px;
	}
	.about-img {
		text-align: center;
		margin-top: 45px;
	}
	.about-area {
		.img {
			margin-top: 90px;
		}
	}
	.services-area {
		padding-bottom: 50px;
	}
	.tab {
		.tab_content {
			.react-tabs {
				.react-tabs__tab-list {
					margin-bottom: 30px;
					padding-bottom: 10px;

					li {
						-ms-flex: 0 0 33.3333333333%;
						flex: 0 0 33.3333333333%;
						max-width: 33.3333333333%;
						padding: {
							right: 0;
							left: 0;
						}
						margin-bottom: 25px;
					}
				}
			}
		}
	}
	.fun-fact {
        padding-left: 65px;
        margin-bottom: 30px;
    }
    .col-lg-3:nth-child(3) .fun-fact, .col-lg-3:nth-child(4) .fun-fact {
        margin-bottom: 0;
    }
	.fun-fact {
		i {
			font-size: 25px;
			width: 50px;
			height: 50px;
			line-height: 50px;
		}
		h3 {
			font-size: 30px;
		}
		p {
			font-size: 18px;
		}
	}
	.join-content {
		margin-top: 0;

		h3 {
			font-size: 26px;
		}
	}
	.how-works-area {
		padding-bottom: 50px;
	}
	.single-box {
		margin-bottom: 30px;

		&.with-line {
			&::before {
				display: none;
			}
		}
	}
	.testimonials-area {
		padding-bottom: 50px;
	}
	.faq-img {
		text-align: center;
		margin-top: 45px;
	}
	.pricing-area {
		padding-bottom: 50px;
	}
	.pricingTable {
		margin-bottom: 30px;
	}
	.pricing-table {
		margin-bottom: 30px;
	}
	.page-title {
		height: 300px;

		.bg-top, .bg-bottom {
			display: none;
		}
	}
	.page-title {
		.pattern-2 {
			height: 135px;
		}
	}
	.footer-area {
		padding-bottom: 0;
	}
	.single-footer {
		margin-bottom: 30px;
	}
	.copyright-area {
		margin-top: 50px;
	}

	.repair-main-banner {
		padding: {
			top: 160px;
			bottom: 270px;
		}
	}
	.repair-banner-content {
		text-align: center;

		h2 {
			line-height: 1.2;
			font-size: 40px;
			margin-bottom: 20px;
		}
		p {
			font-size: 17px;
			margin: 0 auto 25px;
		}
	}
	.repair-home-slides.owl-theme .owl-dots {
		left: 0;
		bottom: 24%;
		right: 0;
	}
	.banner-video {
		margin-top: 50px;

		a {
			margin-left: -0;
		}
	}
	.repair-boxes-area {
		padding-bottom: 0;
	}
	.single-repair-box {
		&::before, &::after {
			display: none;
		}
	}
	.repair-about-image {
		text-align: center;
		margin-top: 35px;

		img {
			&:nth-child(2) {
				width: 350px;
				position: relative;
			}
			&:nth-child(4), &:nth-child(5) {
				display: none;
			}
		}
	}
	.repair-services-inner .col-lg-4:nth-child(2) {
		border: none;
		margin: {
			top: 30px;
			bottom: 30px;
		}
	}
	.single-repair-feedback {
		padding: 20px;
	}
	.gallery-area {
		padding-bottom: 50px;
	}
	.repair-blog-area {
		padding-bottom: 50px;
	}
	.repair-subscribe-content {
		form {
			.form-group {
				width: 33%;
				padding-right: 15px;
			}
			.btn {
				width: 21%;
				margin-top: 20px;
			}
		}
	}
	.repair-subscribe-area {
		margin: {
			top: -80px;
			bottom: 80px;
		}
	}
	.repair-footer-area {
		padding-top: 80px;
	}
	.single-footer-widget {
		margin-bottom: 30px;
	}
	.repair-copyright-area {
		margin-top: 50px;
	}
}

@media only #{$media} and ($feature_min : $value_four) and ($feature_max : $value_five) {
    .main-banner-content {
		margin-top: 0;
	}
	.single-features {
		padding: 20px;
	}
	.tab .tab_content .react-tabs .react-tabs__tab-list li {
		font-size: 15px;
	}
	.join-content {
		margin-top: 35px;
	}
	.testimonials-item {
		padding: 20px;
	}
	.repair-home-slides.owl-theme .owl-dots {
		left: 4.5%;
	}
}

@media only #{$media} and ($feature_min : $value_six) {
	.repair-home-slides.owl-theme .owl-dots {
		left: 21.1%;
		bottom: 28%;
	}
}
